<template>
    <!-- MODAL -->
    <b-modal
        id="modal-change-status"
        :title="i18nT(`Offboard Employee`)"
        ok-only
        :ok-title="i18nT(`Offboard`)"
        no-close-on-backdrop
        @hidden="resetChangeStatus"
        @ok="validationChangeStatus"
        :ok-disabled="!noticeDate || !departureDate || !statusChangeReason"
    >
        <validation-observer ref="changeStatusForm">
            <b-form class="mt-2">
                <b-row>
                    <b-col sm="12">
                        <b-form-group :label="i18nT(`Employee`)">
                            <v-select
                                v-if="canSelectEmployee"
                                :clearable="false"
                                v-model="selectedItems"
                                :options="availableEmployees"
                                :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                label="title"
                            />

                            <div v-else-if="modalUser" class="d-flex pb-1 pt-05 border-bottom mb-05">
                                <b-avatar
                                    :src="modalUser.user.ImageUrl"
                                    :text="(modalUser.user.FirstName ? modalUser.user.FirstName.charAt(0): '') +
                                          (modalUser.user.LastName ? modalUser.user.LastName.charAt(0) : '')
                                        "
                                    size="4em"
                                    class="badge-light-primary"
                                >
                                </b-avatar>
                                <h4 class="pt-1 ml-1">{{modalUser.user.Label}}</h4>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12">
                        <b-form-group>
                            <b-form-checkbox
                                switch
                                :checked="changeStatusNotification"
                                v-model="changeStatusNotification"
                            >
                                {{ i18nT(`Send notification email?`) }}
                            </b-form-checkbox>
                        </b-form-group>
                        <div v-if="changeStatusNotification">
                            <b-form-group
                                :label="i18nT(`Message Template`)"
                                label-for="messageTemplate"
                            >
                                <b-form-select
                                    v-model="statusChangeTemplate"
                                    text-field="Label"
                                    value-field="Id"
                                    :options="mesasgeTemplates"
                                    @change="changeTemplate"
                                />
                            </b-form-group>
                            <b-form-group
                                :label="i18nT(`Message`)"
                                label-for="messageTemplate"
                            >
                                <b-form-textarea v-model="templateMessage"/>
                            </b-form-group>
                        </div>

                        <b-row class="mt-2">
                            <b-col md="12">
                                <b-form-group
                                    :label="i18nT(`Notice Date`)" class="required"
                                    label-for="message"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Notice Date"
                                        rules="required"
                                    >
                                        <date-picker
                                            reset-button
                                            v-model="noticeDate"
                                            placeholder=""
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-form-group
                                    :label="i18nT(`Departure date`)" class="required"
                                    label-for="message"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Departure date"
                                        rules="required"
                                    >
                                        <date-picker
                                            reset-button
                                            v-model="departureDate"
                                            placeholder=""
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-form-group
                                    :label="i18nT(`Reason`)"
                                    label-for="message"
                                >
                                    <b-form-select
                                        v-model="statusChangeReason"
                                        placeholder=""
                                        :options="reasonList"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-form-group
                            :label="i18nT(`Comment`)"
                            label-for="messageTemplate"
                        >
                            <b-form-textarea v-model="statusChangeComment"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>

</template>

<script>
import {BAvatar, BCol, BForm, BFormCheckbox, BFormGroup, BFormSelect, BFormTextarea, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {dictToSelectArray, dictToSelectArrayWithLabel} from "@core/utils/utils";
import DatePicker from "@core/components/DatePicker.vue";

export default {
    name: "ModalOffboard",
    components: {
        ValidationProvider,
        ValidationObserver,
        BFormCheckbox,
        BFormGroup,
        BCol,
        BRow,
        vSelect,
        BForm,
        BFormTextarea,
        BAvatar,
        BFormSelect,
        DatePicker
    },
    props: {
        employee: {
            type: Object,
            default: null
        },
        canSelectEmployee: {
            type: Boolean,
            default: true
        },
        extNoticeDate: {
            type: String,
            default: null
        },
        extDepartureDate: {
            type: String,
            default: null
        },
    },
    data(){
        return {
            modalUser: null,
            changeStatusNotification: true,
            mesasgeTemplates: [],
            templateMessage: '',
            noticeDate: null,
            departureDate: null,
            reasonList: [],

            availableEmployees: [],
            selectedItems: [],

            statusChangeTemplate: null,
            statusChangeComment: '',
            statusChangeReason: null,
        }
    },
    created(){
        this.$http.get(`settings/messageTemplates`).then(({data}) => {
            this.mesasgeTemplates = data.data.message_templates.filter(
                template => template.Type === 'OFFBOARD'
            )
            this.statusChangeTemplate = this.mesasgeTemplates[0].Id;
            this.templateMessage =
                this.mesasgeTemplates.find(
                    template => template.Id === this.statusChangeTemplate
                ).Message ?? ''
        })

        this.$http.get(`system/offboardingReasons`).then(({data}) => {
            this.reasonList = dictToSelectArray(data.data)
        })

        this.$http.get(`employees/offboardingList`).then(({data}) => {
            this.availableEmployees = dictToSelectArrayWithLabel(data.data);
            console.log("To offboard: ", this.availableEmployees);
        })
    },
    methods: {
        changeTemplate() {
            if (this.statusChangeTemplate) {
                this.templateMessage =
                    this.mesasgeTemplates.find(
                        template => template.Id === this.statusChangeTemplate
                    ).Message ?? ''
            }
        },
        resetChangeStatus() {
            this.selectedItems = [];
            this.modalUser = null;
            this.noticeDate = null;
            this.departureDate = null;
            this.statusChangeReason = null;
            this.statusChangeComment = null;
        },
        validationChangeStatus(event) {
            event.preventDefault()
            this.$refs.changeStatusForm.validate().then(success => {
                if (success) {
                    //TODO
                    const formData = new FormData()
                    if (Array.isArray( this.selectedItems) && this.selectedItems.length > 0) {
                        formData.append('application_id', this.selectedItems.join(','))
                    } else {
                        formData.append('application_id', this.selectedItems)
                    }
                    formData.append('stage', 'offboard')
                    formData.append('message_template', this.templateMessage)

                    formData.append('offboarding_notice', this.noticeDate)
                    formData.append('offboarding_at', this.departureDate)
                    formData.append('offboarding_reason', this.statusChangeReason)
                    formData.append('offboarding_comment', this.statusChangeComment)

                    this.$http
                        .post('employees/changeStage', formData)
                        .then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.i18nT(`Successfully moved to onboarding stage`),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.$bvModal.hide('modal-change-status')
                            this.stage = null
                            this.message_template = null
                            this.noticeDate = null
                            this.departureDate = null
                            this.statusChangeReason = null
                            this.statusChangeComment = null
                            this.refetchData()
                            this.loadStages()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
    },
    watch: {
        employee: {
            handler: function (val) {
                if (val) {
                    //debugger;
                    this.modalUser = val;
                    this.selectedItems = [val.application.Id];
                }
            },
            deep: true
        },
        extNoticeDate: {
            handler: function (val) {
                if (val) {
                    this.noticeDate = val;
                }
            },
        },
        extDepartureDate: {
            handler: function (val) {
                if (val) {
                    this.departureDate = val;
                }
            },
        },
    }
}
</script>

<style scoped lang="scss">

</style>
