<template>
    <div>
        <b-modal
            id="modal-import-csv"
            :title="i18nT(`Import csv`)"
            :ok-only="page === 0"
            :cancel-title="i18nT(`Back`)"
            :ok-title="page === 0 ? i18nT(`Next`) : i18nT(`Import`)"
            no-close-on-backdrop
            @ok="okButtonClicked"
            @hidden="resetForm"
            @cancel="backButtonClicked"
            :ok-disabled="!canSave"
            size="xl"
        >
        <slot v-if="page === 0"></slot>
        <b-row 
            v-if="(importType && importType.text && page === 0) || importType === null && page === 0"
            :class="false ? 'border-bottom pb-2' : null"
            class="mt-2"
        >
        <b-col cols="12" md="3">
            <h5>
                {{ i18nT(`Attached csv file`) }}
            </h5>
            </b-col>
            <b-col cols="12" md="9">
              <b-form-file
                  v-model="csvFile"
                  ref="filePicker"
                  accept=".csv"
                  class=""
                  @change="parseCsv($event.target.files[0])"
              />
              <small class="pt-3">
                {{ i18nT(`You can save and convert your Excel or Numbers file to a CSV format.`) }}
                        {{ i18nT(`Instructions: `) }}
                    <a 
                        target="_blank"
                        href="https://support.microsoft.com/en-gb/office/import-or-export-text-txt-or-csv-files-5250ac4c-663c-47ce-937b-339e391393ba#:~:text=Go%20to%20File%20%3E%20Save%20As,or%20CSV%20(Comma%20delimited).">
                        Excel
                    </a>
                    <span>|</span>
                    <a 
                        target="_blank"
                        href="https://support.apple.com/en-gb/guide/numbers/tan3b922d4ad/mac">
                        Numbers
                    </a>
              </small>
            </b-col>

        </b-row>
        <b-row v-if="page === 0">
            <b-col md="12" class="pt-1">
                <hr>
            </b-col>
        </b-row>
            <b-row  v-if="matchingColumns.length > 0 && page === 0">
                <b-col cols="12" md="3" class="mt-2" >
                    <h5>{{ i18nT(`Avaliable fields`) }}
                        <font-awesome-icon
                            class="text-primary"
                            :icon="['fas', 'info-circle']" 
                            v-b-tooltip.hover.right="i18nT(`This is a list of columns you can align with your CSV data. Columns marked as “Required” must be matched to successfully proceed with your data import.`)"
                        />
                    </h5>

                    <!-- <p class="font-small-3">{{ i18nT(`Below, you’ll find a list of columns you can align with your CSV data. Columns marked as “Required” must be matched to successfully proceed with your data import.`) }}</p> -->
                    <ul class="pl-0">
                        <li v-for="(cols, index) in columnOption" :class="getColumnClass(cols)" class="list-style-none" :key="'column' + index">
                            <span v-if="cols.text !== 'None'">
                                <font-awesome-icon
                                    :icon="['fas', getColumnIcon(cols)]" 
                                />
                                <span>
                                    {{ cols.text}}
                                </span>
                            </span>
                        </li>
                    </ul>
                </b-col>
                <b-col cols="12" md="9">
                    <div class="w-100 text-center my-2">
                        <p class="mb-0 pb-0">{{ i18nT(`Click on a column header to match it to one of the available import columns`) }}</p>
                        <small>{{ i18nT(`Note: Only checked columns will be saved`) }}</small>
                    </div>
                    <div class="mt-2 w-100 overflow-x-scroll display-block sk-scroll-wrap sk-scroll-container">
                    <div class="sk-scroll-block" :style="`width:`+(matchingColumns.length*184)+`px;`">
                        <div
                            v-for="(column, index) in matchingColumns"
                            :key="`column-${index}`"
                            class="sk-csv-column border"
                            :class="getColumnIcon(column) === 'times' ? 'sk-csv-column-opacity' : ''"
                        >
                            <div class="sk-csv-header p-05 d-flex justify-content-between border-bottom"
                                v-b-tooltip.top="column.matchingColumn.value ? i18nT(`Data from your CSV column '${column.csvColumn}' will be imported as '${trimRequiredName(column.matchingColumn.text)}'`) : i18nT(`Data from this column will not be imported`)"
                            >
                                <div>
                                    <font-awesome-icon
                                        :icon="['fas', 'times']" 
                                        :class="column.matchingColumn.value ? 'text-primary cursor-pointer' : 'text-muted'"
                                        @click="() => getColumnIcon(column) === 'times' ? null : clearMatchedColumn(column)"
                                     />
                                </div>
                                <div @click="openMatchedModal(index)" class="cursor-pointer text-truncate">
                                    <div class="d-flex justify-content-end">
                                        <h5 class="text-primary mb-0 mr-1">{{column.csvColumn}}</h5>
                                        <feather-icon icon="Edit3Icon" size="15" class="align-middle text-primary"/>
                                    </div>
                                <div class="text-truncate sk-csv-single-row">
                                    <small v-if="column.matchingColumn && column.matchingColumn.text !== 'None'">{{column.matchingColumn.text}}</small>
                                </div>
                                </div>
                            </div>

                            <div class="sk-csv-rows px-1 py-05">
                                <div v-for="(sample, index) in column.samplesRows"
                                    :key="`sample-${index}`"
                                    class="w-100 pt-05 sk-csv-single-row"
                                    :class="sample ? 'border-bottom' : 'hidden'"
                                >
                                    <div class="text-truncate font-italic" v-if="sample" v-html="sample"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </b-col>
                <!-- <b-col cols="12" md="9">
                    <div class="mt-2 w-100 overflow-x-scroll display-block sk-scroll-wrap">
                    <div class="sk-scroll-block" :style="`width:`+(matchingColumns.length*184)+`px;`">
                        <div
                            v-for="(column, index) in matchingColumns"
                            :key="`column-${index}`"
                            class="sk-csv-column border"
                            :class="getColumnIcon(column) === 'times' ? 'sk-csv-column-opacity' : ''"
                        >
                            <div class="sk-csv-header p-05 d-flex justify-content-between border-bottom">
                                <div>
                                    <font-awesome-icon
                                        :icon="['fas', getColumnIcon(column)]" 
                                        :class="column.matchingColumn.value ? 'text-primary cursor-pointer' : 'text-muted'"
                                        @click="() => getColumnIcon(column) === 'times' ? null : clearMatchedColumn(column)"
                                     />
                                </div>
                                <div @click="openMatchedModal(index)" class="cursor-pointer text-truncate">
                                    <div class="d-flex justify-content-end">
                                        <h5 class="text-primary mb-0 mr-1">{{column.csvColumn}}</h5>
                                        <feather-icon icon="Edit3Icon" size="15" class="align-middle text-primary"/>
                                    </div>
                                <div class="text-truncate sk-csv-single-row">
                                    <small v-if="column.matchingColumn && column.matchingColumn.text !== 'None'">{{column.matchingColumn.text}}</small>
                                </div>
                                </div>
                            </div>

                            <div class="sk-csv-rows px-1 py-05">
                                <div v-for="(sample, index) in column.samplesRows"
                                    :key="`sample-${index}`"
                                    class="w-100 pt-05 sk-csv-single-row"
                                    :class="sample ? 'border-bottom' : 'hidden'"
                                >
                                    <div class="text-truncate font-italic" v-if="sample">{{sample}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </b-col> -->
            </b-row>
            <b-row
                v-if="page === 1"
            >
                <b-col cols="12" md="12">
                    <div class="w-100 text-center mt-3">
                        <h4 class="mb-0 pb-0">
                            {{ i18nT(`The data in `)}} <strong>{{validRows}} {{ i18nT(`of`) }} {{ allProcessedRows }}</strong> {{validRows === 1 ? i18nT(`row`) : i18nT(`rows`) }} 
                            {{ i18nT(`is valid and will be imported`) }}.
                        </h4>
                        <p class="text-muted font-small-3">
                            {{ i18nT(`Note: when you click import only the valid rows will be imported`) }}.
                        </p>
                    </div>
                </b-col>
                <b-col cols="12" md="12" v-if="errorWithImportedRows !== 0">
                    <div class="w-100 text-center mt-1">
                            <p class="mb-0 pb-0">{{ i18nT(`We encountered issues with these`) }} {{errorWithImportedRows}} {{errorWithImportedRows === 1 ? i18nT(`row`) : i18nT(`rows`) }}</p>
                    </div>
                </b-col>
                <b-col cols="12" md="12" v-if="errorColumns.length > 0">
                    <div class="mt-2 w-100 overflow-x-scroll display-block sk-scroll-container">
                        <div class="sk-scroll-block ml-auto mr-auto" :style="`width:`+(errorColumns.length*184)+`px;`">
                            <div
                                v-for="(column, index) in errorColumns"
                                :key="`column-${index}`"
                                class="sk-csv-column border"
                            >
                                <div class="sk-csv-header p-05 d-flex justify-content-between border-bottom">
                                    <div class="text-truncate">
                                        <div class="d-flex">
                                            <h5 class="text-primary mb-0 mr-1">{{column.csvColumn}}</h5>
                                        </div>
                                    <div class="text-truncate sk-csv-single-row">
                                        <small v-if="column.apiColumn !== 'None'">{{column.apiColumn}}</small>
                                    </div>
                                    </div>
                                </div>

                                <div class="sk-csv-rows px-1 py-05">
                                    <div v-for="(row, index) in column.rows"
                                        :key="`row-${index}`"
                                        class="w-100 pt-05 sk-csv-single-row border-bottom"
                                        :class="row.error === '' ? '' : 'text-danger'"
                                        v-b-tooltip="row.error"
                                    >
                                        <div class="text-truncate font-italic" v-if="row">{{row.value ? row.value : i18nT(`- no data -`)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-modal>

      <b-modal
          id="column-select-modal"
          @ok="setColumnMatch"
          :ok-disabled="!columnName"
          :title="i18nT(`Match columns`)"
      > 
        <p class="py-1">
            {{ i18nT(`Match the data from your CSV '${currentColumnName}' column to the following system column:`) }}
        </p>
        <v-select
            v-model="columnName"
            :options="columnOption"
            label="text"
            :selectable="selected => selected.disabled !== true"
            class="pb-1"
        ></v-select>
      </b-modal>
    </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BListGroupItem,
  BLink,
  BFormFile,
    BFormCheckbox,
    VBTooltip

} from 'bootstrap-vue'

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {API_KEY} from "@core/utils/constants";
import useAuth from '@/auth/useAuth';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select'
import SweetAlertConfirmOption from '@/views/extensions/sweet-alert/SweetAlertConfirmOption.vue';
import ModalLeavesEdit from '@/views/hr/LeaveRequest/ModalLeavesEdit.vue';
import { dictToSelectArray } from '@core/utils/utils'



export default {
    components: {
    BFormFile,
    BFormCheckbox,
    VueDropzone: vue2Dropzone,
    BLink,
    BButton,
    BCol,
    BRow,
    BListGroupItem,
    vSelect,
},
directives: {
        'b-tooltip': VBTooltip
    },

    props: {
        className: {
            type: String,
            required: true
        },
        importType: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            document: {
                Discount: 0,
                IsInvoice: 0,
                IsRecurring: 0,
                Repeating: false,
                Type: 1,
                RecordType: 1,
                Category: 1,
                InvoiceDocuments: [],
                InvoiceFile: null,

            },
            currentColumnName: '',
            page: 0,
            separator: '',
            requiredColumns: [],
            columnName: null,
            currentIndex: null,
            matchingColumns: [],
            columnOption: [],
            addedColumns: [],
            validRows: 0,
            errorWithImportedRows: 0,
            allProcessedRows: 0,
            errorColumns: [],
            csvFile: null,
            dropzoneOptions: {
                url: process.env.VUE_APP_API_URL+'invoices/invoiceAttachments',
                addRemoveLinks: true,
                autoProcessQueue: true,
                clickable: true,
                dictDefaultMessage: this.i18nT(`Drag and drop additional files here`),
                dictRemoveFile: this.i18nT(`Remove`),
                //dictRemoveFileConfirmation: this.i18nT(`Are you sure you want to remove this file?`),
                dictFileTooBig: this.i18nT(`File is too big ({{filesize}}MB). Max filesize is: {{maxFilesize}}MB`),
                dictInvalidFileType: this.i18nT(`Only Csv files are allowed`),
                dictCancelUpload: this.i18nT(`Cancel`),
                dictCancelUploadConfirmation: this.i18nT(`Are you sure you want to cancel this upload?`),
                dictMaxFilesExceeded: this.i18nT(`You can attach only one file`),
                maxFilesize: 5,
                maxFiles: 1,
                acceptedFiles: 'image/*,application/pdf',
                headers: {
                    'Api-Key': API_KEY,
                    'Auth-Key': useAuth.getToken(),
                },
            },
            dropzoneHasError: false,
        }
    },
    created() {
        // this.fetchColumns()
    },
    computed: {
        canSave() {
            let flag = true
             this.requiredColumns.forEach(req => {
               let colOption = this.columnOption.find(col => col.value === req)
               if(colOption && !colOption.disabled) {
                    flag = false
               }
            })
            return flag
        }
    },
    methods: {
        trimRequiredName(name) {
            return name.substring(0, name.indexOf(' ('))
        },
        clearMatchedColumn(col) {
            col.matchingColumn.value = null
            col.matchingColumn.text = 'None'
            this.disableCol()
        },
        fetchColumns(){
            let url = `${this.className}/importColumns`

            if(this.importType) {
                url += `?import_module=${this.importType.module}`
            }

            this.$http.get(url)
                .then(({data}) => {
                    this.columnOption = data.data.import_columns.map(col => ({
                    value: col.value,
                    text: col.text + (col.required === 1 ? ` (${this.i18nT(`Required`)})` : ""),
                    required: col.required,
                    disabled: false
                    }))
                    this.requiredColumns = this.columnOption.map(col => col.required === 1 ? col.value : false)
                    this.columnOption.unshift({
                    value: null,
                    text: this.i18nT(`None`)
                    })
                })
        },
        okButtonClicked(bvModalEvent) {
            bvModalEvent.preventDefault()
            if(this.page === 0) {
                this.submitCsv('validate')
                this.page = 1
            } else {
                this.submitCsv('import')
            }
        },
        backButtonClicked(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.page = 0
            this.errorColumns = []
            this.errorWithImportedRows = 0
            this.validRows =  0
        },
        getColumnIcon(col) {
            if(col.disabled || (col.matchingColumn && col.matchingColumn.value)) {
                return 'check'
            } else {
                return "times"
            }
        },
        getColumnClass(col) {
            if(col.disabled) {
                return 'text-success'
            } else if (col.required === 1) {
                return "text-danger"
            } else {
                return ""
            }
        },
        submitCsv(importOrValidate) {
            let payload = this.matchingColumns.filter(col => col.matchingColumn.value !== null) 
            payload = payload.map(load => ({csvColumn: load.csvColumn, backEndColumn: load.matchingColumn.value}))
            console.log(payload)

            let formData = new FormData()
            formData.append('columns', JSON.stringify(payload))
            formData.append('import_file', this.csvFile)
            formData.append('separator', this.separator)
            formData.append(importOrValidate, "1")

            if(this.importType && this.importType.import_type) {
                formData.append('import_type', this.importType.import_type)
            }

            this.$http.post(`${this.className}/import`, formData)
            .then(({ data }) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.successMessage(data),
                        icon: 'InfoIcon',
                        variant: 'success',
                        text: data.data.skipped_count > 0 ? this.i18nT(`Imported rows: `) + data.data.imported_count + '<br />' + this.i18nT(`Skipped rows: `) + data.data.skipped_count : ''

                    }
                })
                this.validRows = data.data.success_count
                this.allProcessedRows = data.data.lines_count

                if(importOrValidate === "import") {
                    this.$emit('imported', true)
                    this.$bvModal.hide('modal-import-csv');
                }
            })
            .catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(error),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
                console.log("error: ", error.response.data)
                this.validRows = error.response.data.data.success_count
                this.allProcessedRows = error.response.data.data.lines_count
                this.errorWithImportedRows = error.response.data.data.errors_count
                
                this.errorColumns = error.response.data.data.error_columns
                console.log("error cols: ", this.errorColumns)
            })
        },
        resetForm() {
            this.page = 0
            this.csvFile = null;
            this.errorColumns = []
            this.errorWithImportedRows = 0
            this.validRows =  0
        },
        queComplete() {
            let self = this

            setTimeout(function () {
                self.$refs.mainDropzone.removeAllFiles();
                self.dropzoneHasError = false
            }, 4000);
        },
        dropzoneError(message) {
            this.dropzoneHasError = true;
            console.log(message)
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                timeout: 9000,
                props: {
                    title: this.i18nT(message.previewElement.childNodes[7].innerText),
                    text: message.upload.filename,
                    icon: 'InfoIcon',
                    variant: 'danger',
                    timeout: 20000,
                },
            })
        },
        onFileAdded() {
            console.log('file')
        },
        dropzoneSuccess(file, res) {
            console.log("Returned data: ", file, res)
            this.document.InvoiceDocuments.push(res.data)
        },
        dropzoneAddParams() {
            return
        },
        parseCsv(file){
          let self = this;
          const reader = new FileReader();
          console.log('file', file);

          if(file.type !== "text/csv") {
            self.$swal({
                title: this.i18nT(`Wrong file format! Please import a csv file.`),
                icon: 'warning',
                confirmButtonText: this.i18nT(`Ok`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            })
            throw new Error(`A wrong file has been uploaded`)
          }


          reader.onload = function (event) {
            const csvData = event.target.result;
            const lines = csvData.split(/\r\n|\n/); // Split by line breaks

            self.separator = lines[0].includes(';') ? ';' : ','; // Check if the first line contains a semicolon or a comma
            const headers = lines[0].split(self.separator); // Assuming the first line contains headers
            const result = [];

            for (let i = 1; i < lines.length; i++) {
              const values = lines[i].split(self.separator);
              const obj = {};

              for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = values[j];
              }

              result.push(obj);
            }

            console.log(result);
            self.prepareColumns(result); // You can use or process the 'result' array as needed
          };
          reader.readAsText(file);
        },
        prepareColumns(array) {
          let columnNames = Object.keys(array[0]);
          let self = this;
          columnNames.forEach(item => {
            let oneEntry = {
              csvColumn: item,
              matchingColumn: {
                value: null
              },
              toImport: false,
              samplesRows: array.map(row => row[item] === "" ? "&nbsp;" : row[item]).slice(0, 5)
            }
            self.ensureMinimumEntries(oneEntry.samplesRows)

            self.matchingColumns.push(oneEntry);
            //scvColumn, objColumn, samplesRows
          })
      },
      ensureMinimumEntries(columnLines, minLength = 5) {
            while (columnLines.length < minLength) {
                columnLines.push("&nbsp;");
            }
            return columnLines;
        },
      openMatchedModal(columnIndex){
        this.currentIndex = columnIndex;
        this.currentColumnName = this.matchingColumns[this.currentIndex].csvColumn
        this.$bvModal.show('column-select-modal');
      },
      setColumnMatch(){
       this.matchingColumns[this.currentIndex].matchingColumn = {...this.columnName};
       this.columnName = null
       this.disableCol()
      },
      disableCol() {
            this.columnOption.forEach(col => col.disabled = false)

            this.matchingColumns.map(col => {
                for (let i = 0; i < this.columnOption.length; i++) {
                    if(col.matchingColumn && col.matchingColumn.value && this.columnOption[i].value === col.matchingColumn.value && this.columnOption[i].value !== null) {
                        this.columnOption[i].disabled = true
                    }
                } 
            })
        },
    },
    watch: {
        csvFile() {
            this.matchingColumns = [];
            this.fetchColumns();
        }
    }
}
</script>

<style>

.sk-scroll-wrap {
    height: 250px;
    overflow-y: hidden;
}

.sk-csv-column{
    width: 180px;
    height: 160px;
    display: inline-table;
}

.sk-csv-column-opacity {
    opacity: 0.65;
}

.sk-scroll-block{
    padding-bottom: 10px;
}

.sk-scroll-container {
    max-height: 280px;
    overflow: auto;
}

.sk-csv-single-row:last-of-type {
    border-bottom: 0 !important;
}

.sk-csv-single-row {
    max-width: 160px;
}

.sk-csv-header {
    height: 48px;
}

</style>
