import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'
import useAuth from '@/auth/useAuth'
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useEmployeeList(isTemplate) {
  const refDocumentListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
    { key: 'name', label: i18nT(`Name`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
    { key: 'department', label: i18nT(`Department`), sortable: true },
    { key: 'email', label: i18nT(`Email`), sortable: true },
    { key: 'phone', label: i18nT(`Phone`), sortable: true },
    { key: 'status', label: i18nT(`Status`), sortable: true },
    {
      key: 'action',
      label: i18nT(`Actions`),
      thClass: 'tbl-actions',
      tdClass: 'sticky-column',
      stickyColumn: true
    },
  ]
  const perPage = ref(10)
  const totalDocuments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const department = ref('')
  const group = ref('')
  const backup = ref([])
  const employees = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value
      ? refDocumentListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    }
  })

  const refetchData = () => {
    refDocumentListTable.value.refresh()
  }

  watch(
    [
      currentPage,
      perPage,
      department,
      group,
      isSortDirDesc,
      sortBy,
      searchQuery,
    ],
    () => {
      refetchData()
    }
  )

  const fetchDocuments = (ctx, callback) => {
    const curWorkspace = useAuth.getCurWorkspace()

    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      sort_by: sortBy.value,
      sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      search: searchQuery.value
    }

    if (isTemplate) {
      params.templates = 1
    }

    if (department.value != null) {
      params['filters[department]'] = department.value
    }
    if (group.value != null) {
      params['filters[group]'] = group.value
    }

    axios
      .get(`employees`, {
        params,
      })
      .then(({ data }) => {
        if (data.data.records) {
          employees.value = data.data.records
          callback(data.data.records)
        } else {
          employees.value = []
          callback([])
        }

        totalDocuments.value = data.data.pagination.records_count
      })
  }

  return {
    fetchDocuments,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,
    totalDocuments,
    department,
    group,
    employees,
    refetchData,
  }
}
