<template>
    <b-modal
        id="modal-edit-leave"
        size="lg"
        no-close-on-backdrop
        :title="i18nT(`Edit time off allowance`)"
        :ok-title="i18nT(`Save`)"
        @ok="saveItem"
    >
        <b-row>
            <b-col cols="12" class="text-center py-2">
                <h3>{{i18nT(`Editing time off for`)}} {{editableDay.Year}}</h3>
            </b-col>

        </b-row>
        <b-row>
            <b-col cols="3">
                <b-form-group
                    :label="i18nT(`Days from last year`)"
                    label-for="prevYearDays"
                >
                    <b-form-input
                        id="prevYearDays"
                        v-model="editableDay.PrevYearDays"
                        type="number"
                        :min="0"
                        :max="365"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group
                    :label="i18nT(`Default days`)"
                    label-for="companyDays"
                >
                    <b-form-input
                        id="companyDays"
                        v-model="editableDay.CompanyDays"
                        type="number"
                        disabled
                        :min="0"
                        :max="365"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group
                    :label="i18nT(`Additional days off`)"
                    label-for="additionalDays"
                >
                    <b-form-input
                        id="additionalDays"
                        v-model="editableDay.AdditionalDays"
                        type="number"
                        :min="0"
                        :max="365"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <span class="">{{i18nT(`Total days off`)}}</span>
                <h3 class="pt-05">{{editableDay.TotalDays}}</h3>
            </b-col>
        </b-row>

    </b-modal>
</template>

<script>
import {
    BModal,
    BFormGroup,
    BFormInput,
    //BFormSelect,
    //BButton,
    BRow,
    BCol,

} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
    name: "ModalLeavesEdit",
    components: {
        BModal,
        BFormGroup,
        BFormInput,
        //BFormSelect,
        //BButton,
        BRow,
        BCol,
    },
    data(){
        return {
            editableDay: {
                PrevYearDays: 0,
                CompanyDays: 0,
                AdditionalDays: 0,
                TakenDays: 0,
            }
        }
    },
    props: {
        leaveDay: {
            type: Object,
            default: () => {
                return {
                    PrevYearDays: 0,
                    CompanyDays: 0,
                    AdditionalDays: 0,
                    TakenDays: 0,
                };
            }
        },
    },
    created() {
        this.editableDay = Object.assign({}, this.leaveDay);
    },
    methods: {
        saveItem() {
            let formData = new FormData;

            formData.append("PrevYearDays", this.editableDay.PrevYearDays);
            //formData.append("CompanyDays", this.editableDay.CompanyDays);
            formData.append("AdditionalDays", this.editableDay.AdditionalDays);
            formData.append("id", this.leaveDay.Id);


            this.$http.post(`leaves/leaveDays`, formData).then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.successMessage(response),
                        icon: "CheckIcon",
                        variant: "success",
                    },
                });
                this.$emit("onLeaveUpdated");
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.errorMessage(error),
                        icon: "XIcon",
                        variant: "danger",
                    },
                });
            });
        },
        cancel() {
            this.$emit("cancel");
        },
    },
    watch: {
        leaveDay: {
            handler: function (val) {
                this.editableDay = Object.assign({}, val);
            },
            deep: true,
        },
        editableDay: {
            handler: function (val) {
                this.editableDay.TotalDays = parseInt(val.PrevYearDays ? val.PrevYearDays : 0) + parseInt(val.CompanyDays ? val.CompanyDays : 0) + parseInt(val.AdditionalDays ? val.AdditionalDays : 0);
            },
            deep: true,
        },
    }
}
</script>
<style scoped lang="scss">

</style>
